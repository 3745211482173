<template>
  <div style="width:100%" class="icons">
    <div>
      <v-container class="d-flex justify-right mb-6" fluid>
        <v-row>
          <v-col v-for="(icon, i) in this.$store.state.icons" :key="i" cols="6">
            <v-row>
              <v-col cols="1">
                {{ i }}
              </v-col>
              <v-col>
                <v-icon cols="1"> ${{ icon.name }} </v-icon>
              </v-col>
              <v-col cols="9">
                {{ icon.name }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
